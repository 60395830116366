@import '../../theme/constants';

.Button{
  font-size: 14px;
  cursor: pointer;
  &:focus {
    outline: none !important;
  }
  &.light{
    background: linear-gradient(90deg, $gradient--light, $gradient--dark);
    padding: 1rem 2rem;
    border: solid transparent 0;
    font-size: 14px;
  }
  &.dark{
    border-radius: 50px;
    border: none;
    @extend %shadow-object;
    padding: 1rem;
    > * {
      padding: 0 !important;
      margin: 0 !important;
    }
    &.alt{
      opacity: .5;
    }
  }
}
@import 'src/theme/theme';
$idWidth: 100px;
$width: 550px;
.UseCase {
  .card--useCase, >div {
    padding: $content-padding 2.5rem $content-padding $content-padding;
  }
  .card--useCase{
    width: $width;
    position: relative;
    height: 475px;

    @include media("<=650px"){
      height: initial;
      margin-right: 50px;
    }
  }
  .concordId {
    height: $idWidth;
    width: $idWidth;
    padding: .5rem;
    overflow: hidden;

    > div { // outer ring
      padding: .75rem;
      > div {
        height: 100%;
        width: 100%;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: .75rem;
        >div {
          height: 100%;
          width: 100%;
          border-radius: 50%;
          background: white;
        }
      }
      div[radial="0"]{
        background: conic-gradient(from 90deg at 50% 50%, #E6959E -22.54deg, #E8C1D0 44.68deg, #96C4D2 138.93deg, #F1E2D2 207.08deg, #BFE4DF 270.58deg, #E6959E 337.46deg, #E8C1D0 404.68deg) !important;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
      div[radial="1"]{
        background: conic-gradient(from 90deg at 50% 50%, #96C4D2 0deg, #E8C1D0 50.63deg, #F1E2D2 270deg, #BFE4DF 315deg, #E6959E 337.46deg, #96C4D2 360deg) !important;
        transform: matrix(-0.26, -0.97, -0.97, 0.26, 0, 0);
      }
      div[radial="2"]{
        background: conic-gradient(from 90deg at 50% 50%, #E6959E 0deg, #BFE4DF 97.5deg, #96C4D2 277.5deg, #F1E2D2 348.75deg, #E8C1D0 360deg) !important;
        transform: matrix(1, 0, 0, -1, 0, 0);
      }
    }

    & {
      @extend .shadow-object;
      @include aspect_ratio(1/1);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  &--header{
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: calc(#{$idWidth} + 2rem) 1fr;
    align-items: center;
    margin-bottom: 2rem;
    h3{
      padding-bottom: 1rem;
    }
  }
  .button{
    &--prev, &--next{
      cursor: pointer;
      @include aspect_ratio(1/1);
      border-radius: 50% !important;
      width: $idWidth * .80 !important;
      position: absolute;
      >div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    &--prev{
      top: 100px;
      right: -40px;
    }
    &--next{
      top: 200px;
      right: -40px;
    }
  }
  .Button{
    margin: 1rem 0;
  }
  .Button:first-of-type{
    margin-right: 1.5rem;
  }
  .card--useCaseImage {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 400px;
    img {
      //object-fit: contain;
      width: 100%;
      height: auto;
    }
    @include media("<=550px"){
      max-width: 375px;
    }
  }
}
@import "_constants";
@import "_mixins";
html {
  scroll-behavior: smooth;
}
body{
  font-family: 'GT America Mono', sans-serif;
  text-rendering: optimizeLegibility;
  font-weight: 300;
  font-size: 100%;
  background-color: $page--color;
  color: $font-color--dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *, *:before, *:after {
    box-sizing: border-box;
  }

  main {
    max-width: 100vw;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    > div {
      max-width: 100vw;
      min-height: 100vh;
      min-height: calc(var(--vh, 1vh) * 100);
    }
  }

  font-size: 1rem;
}


h1, h2, h3, h5, p, span, em {
  margin: 0 !important;
}

h1{
  font-family: 'GT America Regular', sans-serif;
  font-size: 100px !important;
  letter-spacing: -0.16px;
  font-weight: normal !important;
}
h2 {
  font-family: 'GT America Regular', sans-serif;
  font-size: 60px !important;
  letter-spacing: -0.09px;
  font-weight: normal !important;
}
h3 {
  font-family: 'GT America Mono', sans-serif;
  font-size: 30px !important;
  letter-spacing: -0.05px;
  font-weight: normal !important;
}
h4 {
  font-family: 'GT America Mono', sans-serif;
  font-size: 18px !important;
  letter-spacing: 1.12px;
}
h5 {
  font-family: 'GT America Mono', sans-serif;
  font-weight: 700;
  font-size: 11px !important;
  letter-spacing: 1.72px;
  text-transform: uppercase;
}
p{
  font-family: 'GT America Mono', sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: -0.02px;

  &.quote{
    @extend %quote-test;
  }
}
em{
  font-style: normal;
}


.shadow-object{
  @extend %shadow-object;
}

.content{
  max-width: $content-maxWidth;
  margin: $content-margin/2 auto;
  &.no-bottom{
    margin: $content-margin/2 auto 0;
  }
  &.no-top{
    margin: 0 auto $content-margin/2;
  }
  &--center{
    @extend .content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.col-two{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  >* {
    margin: $content-margin/4 auto;
  }
  @include media("<=1024px"){
    justify-content: center;
  }
}

.col-one{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  >* {
    margin: $content-margin/4;
  }
  @include media("<=1024px"){
    justify-content: center;
  }
}

.circle{
  @extend %shadow-object;
  @include aspect_ratio(1/1);
  border-radius: 50% !important;
}


@import 'src/theme/theme';
.Xr{
  model-viewer{
    width: 100%;
    height: 500px;
  }

  :not(:defined) > * {
    display: none;
  }

  .slot.ar-button:not(.enabled) {
    display: block !important;
  }

  model-viewer {
    background-color: #eee;
    --poster-color: #eee;
  }
  #ar-button {
    //background-image: url(../assets/ic_view_in_ar_new_googblue_48dp.png);

    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: green;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 132px;
    padding: 0px 16px 0px 40px;
    font-family: Roboto Regular, Helvetica Neue, sans-serif;
    font-size: 14px;
    color:#4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #DADCE0;
  }

  #ar-button:active {
    background-color: #E8EAED;
  }

  #ar-button:focus {
    outline: none;
  }

  #ar-button:focus-visible {
    outline: 1px solid #4285f4;
  }

  @keyframes circle {
    from { transform: translateX(-50%) rotate(0deg) translateX(50px) rotate(0deg); }
    to   { transform: translateX(-50%) rotate(360deg) translateX(50px) rotate(-360deg); }
  }

  @keyframes elongate {
    from { transform: translateX(100px); }
    to   { transform: translateX(-100px); }
  }

  //model-viewer > #ar-prompt {
  //  position: absolute;
  //  left: 50%;
  //  bottom: 175px;
  //  animation: elongate 2s infinite ease-in-out alternate;
  //  display: none;
  //}
  //
  //model-viewer[ar-status="session-started"] > #ar-prompt {
  //  display: block;
  //}
  //
  //model-viewer > #ar-prompt > img {
  //  animation: circle 4s linear infinite;
  //}
}
@import '../../../theme/theme';
$padding: 2rem;
$width: 500px;
.Infrastructure{

  display: grid;
  grid-template-columns: 1fr $width 1fr;
  grid-template-rows: 1fr;

  .content>div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr min-content auto;
    padding: 2rem 0;
    text-align: center;
  }
  > div:nth-of-type(1), > div:nth-of-type(3)  {
    margin: $padding;
    >div {
      @extend .shadow-object;
      overflow: hidden;
      padding: $padding;
      max-height: 100%;
    }
  }
  .part{
    cursor: pointer;
  }
}
@import 'src/theme/theme';
.Transaction{
  position: relative;
  .desc {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    @include media("<=1024px") {
      > div {
        padding-left: 8rem !important;
      }
    }

    > div {
      padding-left: 13rem;
      height: 90%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 60%;
    }

    > img {
      margin: 9% 10% 0 0;
      height: 55%;
    }

    @include media("<=830px") {
      flex-direction: column;
      align-items: center;
      justify-content: initial;
      > div {
        padding: $content-padding * 3 !important;
        padding-bottom: 0 !important;
        width: 100%;
        height: 50%;
      }
      > img {
        margin: 0 !important;
        padding: $content-padding * 2 ($content-padding * 2) ($content-padding * 2) !important;
        width: 75%;
        height: auto;
      }
    }
    @include media("<=530px") {
      > div {
        padding: $content-padding * 5 $content-padding * 1.5 $content-padding * 1.5 $content-padding * 1.5!important;
      }
    }
  }
}
@import '../../../theme/constants';
.Landing{
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  //max-width: 100vw;
  //height: 100vh;
  //height: calc(var(--vh, 1vh) * 100);

  > div:nth-of-type(1){

  }
  > div:nth-of-type(2){
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);


    h1{
      color: $font-color--dark;
      font-size: 250px !important;
      letter-spacing: -0.39px;
      text-align: center;
    }
    h2{
      color: $font-color--dark-grey;
      font-size: 32px;
    }
    em{
      font-style: normal;
      @extend %header-em;
    }
  }
  > div:nth-of-type(3){

  }
}
@import '../../../theme/theme';
.Narrative {
  .clickable{
    cursor: pointer;
  }
  section {
    margin: $section-margin auto;
  }
  h2 {
    text-align: center;
  }
  h4, p {
    em {
      color: $font-color--emphasis;
    }
  }
  a{
    text-decoration: underline solid #181818;
    color: $font-color--dark-grey;
    font-weight: bold;
  }
  .hero-section {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    > p{
      position: absolute;
      top: $content-margin / 3;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 500px;
      color: #f0f0f0;
      z-index: 0;
      transform: translateY(-25%);
      @include media("<=1024px"){
        font-size: 50vw;
        left: -12.5%;
        top: 0;
      }
    }
    .hero-banner {
      z-index: 2;
      text-align: center;
      min-height: 18rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      position: relative;
      padding-top: $content-margin;
      h3 {
        padding: $content-margin 0;
      }
    }
  }

  .card--shortDesc{
    align-items: center;
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    p, h3 {
      max-width: 55ex;
    }
  }
  .circle{
    width: 600px;
    overflow: hidden !important;
    border-radius: 50%;
  }
  .parts{
    .content > h3{
      padding: 0 $content-padding $content-margin/2;
    }
    &--content{
      padding: 0 $content-padding;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: stretch;
      column-gap: 3rem;
      @include media("<=tablet"){
        justify-content: flex-start;
      }

      >div{
        margin: 1rem;
        h4{
          margin: 0;
        }
        .circle{
          margin: 0 0 $content-padding;
          max-height: 75px !important;
          max-width: 75px !important;
          >div {
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
    hr {
      margin-left: calc( ((100vw - #{$content-maxWidth}) / 2 ) + #{$content-padding} );
    }
  }
  .card--confetti{
    height: 18rem;
    display: grid;
    width: 500px;
    > * {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
    }

    &--content{
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $content-padding;
    }
    canvas{
      height: 100%;
      width: 100%;
      pointer-events: none;
    }
  }
  .quote{
    padding: 0 1.5rem;
  }
  .card--multivariate{
    position: relative;
    width: 977px;
    margin: 0 auto;
    .quote{
      transform: translateY(8rem);
      text-align: center;
      background: $page--color;
    }
    .feature-row{
      > div {
        display: grid;
        grid-template-columns: 65px auto;
        align-items: center;
        grid-column-gap: 2rem;
        >div{
          display: flex;
          align-items: center;
          justify-content: center;
        }
        margin: $content-margin / 2 0;
      }
      margin-bottom: -5rem;
    }
    .multi-values{
      display: flex;
      flex-wrap: wrap;
      align-content: space-around;
      justify-content: space-around;
      border-right: 1px solid $border--dark;
      border-bottom: 1px solid $border--dark;
      border-top: 1px solid $border--dark;
      padding-top: 5rem;
      .multi-value{
        display: grid;
        justify-items: center;
        //align-items: center;
        text-align: center;
        grid-template-rows: 60px min-content auto;
        width: 250px;
        margin: 2rem;
        > div{
          height: 60px;
          width: 60px;
        }
      }
    }
    #vr-1{
      width: 1px;
      background: $border--dark;
      height: 60%;
      position: absolute;
      left: 0;
      top: 0;
    }
  }
  .phone-row{
    padding: 2rem 0;
  }
  .ipa-row{
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr 1fr;
    grid-column-gap: 1rem;
    div{
      display: flex;
      justify-content: center;
      align-items: center;
      .circle{
        width: 100%;
      }
    }
  }
  .chart-row{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .slider{
    @include media("<=tablet"){
      font-size: 50vw;
      left: -12.5%;
      top: 0;
    }
    margin: $section-margin * 1.5;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(min-content, 100px));
    grid-column-gap: 1rem;
    height: 250px;
    div {
      width: 200px;
      height: 250px;
      display: flex;
      align-self: center;
      justify-content: center;
    }
    img {
      height: 250px;
      object-fit: contain;
    }
  }
  .footer{
    text-align: center;
    padding-bottom: 2rem;
  }
}
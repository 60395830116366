@import 'src/theme/theme';
$padding: 3rem;
$height: 475px;

$smallHeight: 375px;

.Modular{
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .col {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: $height;
    @include media("<=1024px") {
      margin: 4rem auto !important;
    }
    @include media("<=550px"){
      &:first-of-type{
        margin-bottom: 0;
      }
      &:last-of-type{
        margin-top: 0;
      }
    }
    > div {
      @extend .shadow-object;
      padding: $padding;
      margin: 0 2rem;
      max-height: 100%;
    }
  }
  .modules{
    width: $height;
    height: $height; // delete??
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;

    @include media("<=550px"){
      width: $smallHeight;
      height: $smallHeight;
      margin: 0 !important;
    }

    >div {
      overflow: hidden;
      img {
        object-fit: contain;
        max-width: 100%;
      }
    }

    [data-grid-placment='tr']{
      grid-column: 3 / 5;
      grid-row: 1 / 3;
    }
    [data-grid-placment='tm']{
      grid-column: 2 / 3;
      grid-row: 1 / 1;
    }
    [data-grid-placment='tl']{
      grid-column: 1 / 2;
      grid-row: 1 / 3;
    }
    [data-grid-placment='bl']{
      grid-column: 1 / 2;
      grid-row: 3 / 5;
    }
    [data-grid-placment='c']{
      grid-column: 2 / 3;
      grid-row: 2 / 4;
    }
    [data-grid-placment='cr']{
      grid-column: 3 / 4;
      grid-row: 3 / 4;
    }
    [data-grid-placment='crr']{
      grid-column: 4 / 5;
      grid-row: 3 / 4;
    }
    [data-grid-placment='br']{
      grid-column: 2 / 5;
      grid-row: 4 / 5;
    }
  }
  .desc {
    width: min-content;
    min-width: $height;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
    height: $height/1.30;
    //padding: $padding 4rem !important;

    @include media("<=550px"){
      width: calc(#{$smallHeight} - 2rem) !important;
      height: 600px;
      margin: 0 !important;
      padding: 1rem !important;
    }

    >div {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
      @include media("<=550px"){
        width: calc(100% - 4rem);
        padding-top: 35%;
      }
    }
    >img {
      grid-column: 1 / span 1;
      grid-row: 1 / span 1;
      position: relative;
      top: -250px;
      left: 0;
      //display: none;
      height: calc(#{$height/2.75} - 1rem - (#{$padding} / 2));
      transform: scale(1.5);
    }
  }
}
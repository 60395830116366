$page--color: #F7F7F7;
$page--min-width: 100vw;
$font-color--dark: #353535;
$font-color--dark-grey: #181818;
$font-color--light-grey: #6E6E6E;
$font-color--emphasis: #F8B7C4;

$gradient--light: #F7CACA;
$gradient--dark: #ABBEFC;

$border--dark: #979797;

$content-margin: 6rem;
$section-margin: 4rem;
$content-padding: 2rem;
$content-maxWidth: 1280px;

@font-face {
  font-family: 'GT America Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../src/fonts/GT-America-Medium.OTF');
}
@font-face {
  font-family: 'GT America Regular';
  font-style: normal;
  font-weight: 400;
  src: url('../../src/fonts/GT-America-Regular.OTF');
}

@font-face {
  font-family: 'GT America Mono';
  font-style: normal;
  font-weight: 400;
  src: url('../../src/fonts/GT-America-Mono-Regular.OTF');
}
@font-face {
  font-family: 'GT America Mono Medium';
  font-style: normal;
  font-weight: 500;
  src: url('../../src/fonts/GT-America-Mono-Medium.OTF');
}
@font-face {
  font-family: 'GT America Mono Bold';
  font-style: normal;
  font-weight: 700;
  src: url('../../src/fonts/GT-America-Mono-Bold.OTF');
}


%header-em{
  font-size: 30px;
  color: $font-color--light-grey;
  font-style: normal;
  text-transform: uppercase;
}

%quote-test{
  font-family: 'GT America Regular', sans-serif;
  font-size: 26px;
  letter-spacing: -0.04px;
  em{
    text-decoration: underline #F9D0D0;
    font-style: normal;
  }
  //border: 4px solid #F9D0D0;
}

%shadow-object{
  background: #F7F7F7;
  box-shadow: 9px 9px 16px 0 #E0E0E0, -9px -9px 16px 0 #FFFFFF;
  border-radius: 30px;
  position: relative;
  z-index: 1;
}
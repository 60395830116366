@import 'src/theme/theme';
.Multivariate{
  width: 660px;
  height: 660px;
  //@include aspect_ratio(1/1);
  display: grid;

  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  align-items: center;
  // TODO -- center the descr
  svg{
    //@include aspect_ratio(1/1);

    grid-column: 1 / span 1;
    grid-row: 1 / span 1;

    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 650px;
    * {
      pointer-events: none;
    }
    rect{
      pointer-events: all !important;
    }
    .interactable{
      transform-origin: center;
      cursor: pointer;
    }
  }
  .description-container {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    .description {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      width: 350px;
      margin: 50px auto 0;
      height: 150px;
      padding: 2rem;
    }
  }
}
@import 'src/theme/theme';
.Radial{
  max-width: 680px;
  display: grid;
  align-items: center;

  > * {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
  }
  > img{
    z-index: 1;
  }

  @include media("<600px"){
    transform-origin: center;
    transform: scale(.7);
  }
  @include media("<500px"){
    transform-origin: center;
    transform: scale(.6);
    height: 400px;
  }

  .js-plotly-plot{
    z-index: 2;
    transform-origin: center;
    transform: scale(.8);
    position: relative;
    top: 25px;
  }

  .main-svg{
    background: transparent !important;
  }
  .modebar-container{
    display: none ;
  }
  .slicetext{
    opacity: 0;
    transition: opacity .2s;
  }
  :hover {
    .slicetext {
      opacity: 1;
    }
  }
}